import React, { useState, useEffect } from 'react'
import { HashRouter, Routes, Route, useLocation } from 'react-router-dom'
import ImageLoadWrapper from './ImageLoadWrapper/imageLoadWrapper'
import LandingPage from './LandingPage/landingPage'
import LandingPageMobile from './LandingPageMobile/landingPageMobile'
import TraneRentalTool from './TraneRentalTool/traneRentalTool'
import TraneRentalToolMobile from './TraneRentalToolMobile/traneRentalToolMobile'
import Sushi from './Sushi/sushi'
import SushiMobile from './SushiMobile/sushiMobile'
import DogGate from './DogGate/dogGate'
import DogGateMobile from './DogGateMobile/dogGateMobile'
import Navbar from './navbar/navbar'
import IconGallery from './IconGallery/iconGallery'
// Pages

function Layout({ windowWidth, children }) {
  const location = useLocation()

  return (
    <>
      <Navbar />
      {children}
    </>
  )
}

// Custom hook to get the current window width
const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowWidth
}

function App() {
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth < 768 // Adjust this breakpoint as needed

  return (
    <div style={{ backgroundColor: '#FAF8FF' }}>
      <HashRouter>
        <Layout windowWidth={windowWidth}>
          <ImageLoadWrapper>
            <Routes>
              <Route
                path="/TraneRentalTool"
                element={
                  isMobile ? <TraneRentalToolMobile /> : <TraneRentalTool />
                }
              />
              <Route
                path="/DogGate"
                element={isMobile ? <DogGateMobile /> : <DogGate />}
              />
              <Route
                path="/Sushi"
                element={isMobile ? <SushiMobile /> : <Sushi />}
              />
              <Route
                path="/gallery"
                element={<IconGallery windowWidth={windowWidth} />}
              />
              <Route
                path="/"
                element={isMobile ? <LandingPageMobile /> : <LandingPage />}
              />
            </Routes>
          </ImageLoadWrapper>
        </Layout>
      </HashRouter>
    </div>
  )
}

export default App
