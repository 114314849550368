import styles from './landingPage.module.scss'
import heroImage from '../static/heroImage.svg'
import traneDemo from '../static/traneDemo.png'
import dogGateDemo from '../static/dogG8Demo.png'
import sushiDemo from '../static/sushiDemo.png'
import dotsLeft from '../static/dotsLeft.svg'
import dotsRight from '../static/dotsRight.svg'
import emailIcon from '../static/emailIcon.svg'
import messageIcon from '../static/messageIcon.svg'
import classNames from 'classnames'
import Button from '../button/button'

export const TraneIndex = (props) => {
  const [slide, setSlide] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      setSlide((prev) => prev + 1)
    }, 2500) // 500 seconds = 500,000 milliseconds

    return () => clearTimeout(timer) // Clear the timeout if the component unmounts
  }, [slide]) // Adding `slide` as a dependency to rerun the effect when `slide` changes
  return (
    <div
      className={classNames({
        [styles.indexItem]: true,
        [styles.traneBackground]: true,
        [styles.footerVersion]: props.footerVersion,
      })}
      // Used for finding element for scrolling
      data-index-item
    >
      <div className={styles.traneIndex}>
        <img src={traneDemo}></img>
        <div className={styles.traneIndexDescription}>
          <p className={styles.indexTitle}>Trane rental survey tool</p>
          <p className={styles.indexSubtitle}>
            Reduce paper, increase productivity
          </p>
          <p className={styles.indexDescription}>
            Transitioning from paper-based to digital order management systems
            streamlines operations, minimises errors, and dramatically enhances
            productivity.
          </p>
          <Button
            text="Read more"
            href="/#/TraneRentalTool"
            customStyle={{
              '--button-default-height': '60px',
              maxWidth: '200px',
              marginTop: '50px',
              fontSize: '16px',
            }}
          ></Button>
        </div>
      </div>
    </div>
  )
}

export const DogGateIndex = (props) => {
  return (
    <div
      className={classNames({
        [styles.indexItem]: true,
        [styles.dogGateBackground]: true,
        [styles.footerVersion]: props.footerVersion,
      })}
      // Used for finding element for scrolling
      data-index-item
    >
      <div className={styles.dogGateIndex}>
        <img src={dogGateDemo}></img>
        <div className={styles.traneIndexDescription}>
          <p className={styles.indexTitle}>Dog-G8</p>
          <p className={styles.indexSubtitle}>Track better, stock smarter</p>
          <p className={styles.indexDescription}>
            Facilitate a growing businesses to efficiently manage their
            inventory, preventing costly overstocking and missed sales
            opportunities.
          </p>
          <Button
            text="Read more"
            href="/#/DogGate/"
            customStyle={{
              '--button-default-height': '60px',
              maxWidth: '200px',
              marginTop: '50px',
              fontSize: '16px',
            }}
          ></Button>
        </div>
      </div>
    </div>
  )
}

export const SushiIndex = (props) => {
  return (
    <div
      className={classNames({
        [styles.indexItem]: true,
        [styles.sushiBackground]: true,
        [styles.footerVersion]: props.footerVersion,
      })}
    >
      <div className={styles.sushiIndex}>
        <img src={sushiDemo}></img>
        <div className={styles.traneIndexDescription}>
          <p className={styles.indexTitle}>Itadakimasu: food app</p>
          <p className={styles.indexSubtitle}>Wholesome Asian Cuisine</p>
          <p className={styles.indexDescription}>
            Access to a diverse selection of Asian dishes, making it easy for
            users to enjoy their favorite meals quickly and simply.
          </p>
          <Button
            text="Read more"
            href="/#/Sushi/"
            customStyle={{
              '--button-default-height': '60px',
              maxWidth: '200px',
              marginTop: '50px',
              fontSize: '16px',
            }}
          ></Button>
        </div>
      </div>
    </div>
  )
}

import Typewriter from 'typewriter-effect'
import { useEffect, useState } from 'react'

const HelloWorld = () => {
  return (
    <div className={styles.helloWorldText}>
      <Typewriter
        options={{
          strings: ['Hello world'],
          cursor: '_',
          pauseFor: 1000000000000,
          autoStart: true,
          loop: true,
        }}
      />
    </div>
  )
}

export const LandingPage = () => {
  return (
    <div className={styles.page}>
      <div className={styles.banner}>
        <p className={styles.titleText}>Brenda Fuentes</p>
        <img src={heroImage}></img>
      </div>
      <div className={styles.helloWorld}>
        <HelloWorld></HelloWorld>
        <p className={styles.introText}>
          Hello there! I'm Bren - I'm focused on making user experiences smooth
          and engaging. I mix creativity with problem-solving to create designs
          that look great and work even better. I enjoy working with different
          styles, from clean and minimal to bold and futuristic. I'm always up
          for new challenges and love creating digital experiences that make a
          difference!
        </p>
      </div>
      <div className={styles.separatorContainer}>
        <div className={styles.pastWorkTitleContainer}>
          <img className={styles.dots} src={dotsLeft}></img>
          <p className={styles.pastWorkTitle}>What I've been working on</p>
          <img className={styles.dots} src={dotsRight}></img>
        </div>
      </div>
      <TraneIndex></TraneIndex>
      <DogGateIndex></DogGateIndex>
      <SushiIndex></SushiIndex>
      <div className={styles.footerContainer}>
        <p
          className={classNames({
            [styles.indexTitle]: true,
            [styles.getInTouchText]: true,
          })}
        >
          Let's get in touch!
        </p>
        <p className={styles.emailText}>brendamelinafuentes@gmail.com</p>
        <Button
          text="Send a message"
          href="https://www.linkedin.com/in/brenda-melina-fuentes-540200293/"
          icon={messageIcon}
          customStyle={{
            '--button-default-height': '60px',
            maxWidth: '350px',
            alignSelf: 'center',
            justifySelf: 'center',
            '--button-danger-color-dark': '#F57C00',
            '--button-danger-color-light': '#FFFFFF',
            '--button-danger-color': '#FFA726',
            '--button-default-border-radius': '40px',
            '--button-danger-color-hover': '#FFA726',
            '--button-danger-color-active': '#FFA726',
            fontSize: '18px',
          }}
        ></Button>
      </div>
    </div>
  )
}

export default LandingPage

// This is lazy but saves copying styles over. This is the component that appears below each
// detail view page
export const FooterPage = (props) => {
  return (
    <>
      <span style={{ marginTop: '60px' }}></span>
      {props.skip !== 'trane' && <TraneIndex footerVersion={true}></TraneIndex>}
      {props.skip !== 'dogGate' && (
        <DogGateIndex footerVersion={true}></DogGateIndex>
      )}
      {props.skip !== 'sushi' && <SushiIndex footerVersion={true}></SushiIndex>}
      <div className={styles.footerContainer}>
        <p
          className={classNames({
            [styles.indexTitle]: true,
            [styles.getInTouchText]: true,
          })}
        >
          Let's get in touch!
        </p>
        <p className={styles.emailText}>brendamelinafuentes@gmail.com</p>
        <Button
          text="Send a message"
          href="https://www.linkedin.com/in/brenda-melina-fuentes-540200293/"
          icon={messageIcon}
          customStyle={{
            '--button-default-height': '60px',
            maxWidth: '250px',
            alignSelf: 'center',
            justifySelf: 'center',
            '--button-danger-color-dark': '#F57C00',
            '--button-danger-color-light': '#FFFFFF',
            '--button-danger-color': '#FFA726',
            '--button-default-border-radius': '40px',
            '--button-danger-color-hover': '#FFA726',
            '--button-danger-color-active': '#FFA726',
            fontSize: '18px',
          }}
        ></Button>
      </div>
    </>
  )
}
