const PrototypeLink = ({ link, customStyle }) => {
  return (
    <div
      style={{
        ...{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          justifyContent: 'flex-start',
          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
        },
        ...customStyle,
      }}
    >
      <p
        style={{
          marginBottom: '10px',
          fontSize: '18px',
          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
          fontWeight: '500',
        }}
      >
        External Links
      </p>
      <p
        style={{
          fontSize: '18px',
          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
        }}
      >
        Check out the design  <a
        style={{
          textDecoration: 'underline',
          fontSize: '18px',
          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
        }}
        href={link}
      >
        here
      </a>
      </p>
     
    </div>
  )
}

export default PrototypeLink
