import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import homeIcon from './static/homeIcon.png'
import styles from './navbar.module.scss'

const Navbar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [visible, setVisible] = useState(true)
  const location = useLocation()
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const scrollTo = params.get('scrollTo')
    if (scrollTo === 'index-section') {
      scrollToIndex()
    }
  }, [location])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10)
      setPrevScrollPos(currentScrollPos)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [prevScrollPos])

  const scrollToIndex = () => {
    const elements = document.querySelectorAll('[data-index-item]')
    if (elements.length > 0) {
      elements[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }

  const navigateAndScroll = () => {
    scrollToIndex
  }

  const handleWorksClick = () => {
    if (window.location.hash) {
      window.location.href = '/?scrollTo=index-section'
    } else {
      scrollToIndex()
    }
  }

  return (
    <nav
      style={{
        position: 'fixed',
        width: '100%',
        backgroundColor: '#FFFFFF',
        transition: 'transform 0.3s ease-in-out',
        transform: visible ? 'translateY(0)' : 'translateY(-100%)',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: window.location.hash.includes('gallery')
            ? '#FFFFFF'
            : '#faf8ff',
          boxShadow: isScrolled
            ? '0 20px 80px 0 rgba(47,47,47,0.07)'
            : 'none',
        }}
        className={styles.navbarContainer}
      >
        <div className={styles.navbarContent}>
          <img
            onClick={() => (window.location.href = '/')}
            className={styles.homeIcon}
            src={homeIcon}
            alt="Home"
          />
          <div className={styles.navItems}>
            <div className={styles.navbarItem} onClick={handleWorksClick}>
              <p className={styles.navLink} style={{ fontSize: '20px' }}>
                Works
              </p>
            </div>
            <div
              className={styles.navbarItem}
              onClick={() => (window.location.href = '/#/gallery')}
            >
              <p className={styles.navLink} style={{ fontSize: '20px' }}>
                Graphics
              </p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
