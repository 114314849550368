import styles from './dogGateMobile.module.scss'
import Conclusion from './static/Conclusion.svg'
import Cover from './static/Cover.png'
import Description from './static/Description.svg'
import DesignSystem from './static/DesignSystem.svg'
import DesignSystemImage from './static/DesignSystemImage.png'
import HifiWireframe from './static/HifiWireframe.svg'
import LofiWireframe from './static/LofiWireframe.svg'
import LofiWireframeImage from './static/LofiWireframeImage.svg'
import WhatIWouldImprove from './static/WhatIWouldImprove.svg'
import SeeOtherProjects from './static/SeeOtherProjects.svg'
import { FooterPage } from '../LandingPageMobile/landingPageMobile'
import PrototypeLink from '../prototypeLink/prototypeLink'

export const DogGate = () => {
  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <img className={styles.cover} src={Cover} alt="Cover" />
        <img
          className={styles.description}
          src={Description}
          alt="Description"
        />
        <img
          className={styles.lofiWireframe}
          src={LofiWireframe}
          alt="LofiWireframe"
        />
        <img
          className={styles.lofiWireframeImage}
          src={LofiWireframeImage}
          alt="LofiWireframeImage"
        />
        <img
          className={styles.designSystem}
          src={DesignSystem}
          alt="DesignSystem"
        />
        <img
          className={styles.designSystemImage}
          src={DesignSystemImage}
          alt="DesignSystemImage"
        />
        <img
          className={styles.hifiWireframe}
          src={HifiWireframe}
          alt="HifiWireframe"
        />
        <PrototypeLink
          customStyle={{ paddingBottom: '40px' }}
          link="https://www.figma.com/proto/QZyQG6gjuCNTF01gsgqyn7/Trane-portfolio?node-id=1281-16154&node-type=frame&t=PLRnARLRUdmEohTY-1&scaling=scale-down&content-scaling=fixed&page-id=1281%3A14618&starting-point-node-id=1281%3A16157"
        />
        <img className={styles.conclusion} src={Conclusion} alt="Conclusion" />
        <img
          className={styles.whatIWouldImprove}
          src={WhatIWouldImprove}
          alt="WhatIWouldImprove"
        />
        <div className={styles.thankYouContainer}>
          <p>
            Thank you for taking the time to read this case study. I hope you
            found it interesting and enjoyed exploring the design process. If
            you have any questions, comments, or simply want to connect and
            share ideas, feel free to send me a message on LinkedIn
          </p>
        </div>
        <img
          className={styles.seeOtherProjects}
          src={SeeOtherProjects}
          alt="SeeOtherProjects"
        />
        <FooterPage skip="dogGate" />
      </div>
    </div>
  )
}

export default DogGate
