import { AwesomeButton } from 'react-awesome-button'
import 'react-awesome-button/dist/styles.css'

function Button(props) {
  const style = {
    '--button-danger-color': 'white',
    '--button-danger-color-light': '#272727',
    '--button-danger-color-dark': '#CCCCCC',
    '--button-danger-color-hover': 'white',
    '--button-danger-color-active': 'white',
    '--button-danger-border': 'none',
    '--button-default-border-radius': '25px',
    '--button-hover-pressure': '0',
    marginTop: '30px',
    width: '90%',
    maxWidth: '350px',
    fontFamily: "'Poppins', sans-serif",
    ...props.customStyle,
  }

  const iconStyle = {
    marginRight: '16px',
    display: 'inline-flex',
    alignItems: 'center',
    width: '24px',
  }

  const handleClick = (e) => {
    if (props.href && props.href.includes('@')) {
      e.preventDefault()
      window.location.href = `mailto:${props.href}`
    }
    if (props.onClick) {
      props.onClick(e)
    }
  }

  return (
    <AwesomeButton
      type="danger"
      style={style}
      href={props.href && !props.href.includes('@') ? props.href : undefined}
      onPress={handleClick}
      before={props.icon && <img style={iconStyle} src={props.icon} alt="" />}
    >
      {props.text}
    </AwesomeButton>
  )
}

export default Button
