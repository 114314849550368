import React, { useState } from 'react'
import './carousel.css'
import Slider from 'react-slick'
import One from './static/1.svg'
import Two from './static/2.svg'
import Three from './static/3.svg'
import Four from './static/4.svg'
import Five from './static/5.svg'
import Six from './static/6.svg'

const slides = [
  { key: '1', content: One },
  { key: '2', content: Two },
  { key: '3', content: Three },
  { key: '4', content: Four },
  { key: '5', content: Five },
  { key: '6', content: Six },
]

const CustomCarousel = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  }

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
        marginBottom: '100px',
      }}
    >
      <Slider {...settings}>
        {slides.map((slide) => (
          <div key={slide.key}>
            <img src={slide.content} alt={`Slide ${slide.key}`} />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default CustomCarousel
