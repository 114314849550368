import ClientJourneyAfter from './static/ClientJourneyAfter.svg'
import ClientJourneyBefore from './static/ClientJourneyBefore.svg'
import Cover from './static/Cover.png'
import Description from './static/Description.svg'
import DesignProcess from './static/DesignProcess.svg'
import DesignProcessImage from './static/DesignProcessImage.svg'
import DesignSystem from './static/DesignSystem.svg'
import DesignSystemImage from './static/DesignSystemImage.svg'
import HifiWireframe from './static/HifiWireframe.svg'
import LofiWireframe from './static/LofiWireframe.svg'
import LofiWireframeImage from './static/LofiWireframeImage.svg'
import SalesAdministratorAfter from './static/SalesAdministratorAfter.svg'
import SalesAdministratorBefore from './static/SalesAdministratorBefore.svg'
import SeeOtherProjects from './static/SeeOtherProjects.svg'
import TaskFlow from './static/TaskFlow.svg'
import TaskFlowImage from './static/TaskFlowImage.svg'
import TheResearch from './static/TheResearch.svg'
import ResearchInsights from './static/ResearchInsights.svg'
import UsabilityTest from './static/UsabilityTest.svg'
import UserJourney from './static/UserJourney.svg'
import UserPersonas from './static/UserPersonas.svg'
import UserPersonasImage from './static/UserPersonasImage.png'
import WhatDidILearn from './static/WhatDidILearn.svg'
import styles from './traneRentalToolMobile.module.scss'
import { FooterPage } from '../LandingPageMobile/landingPageMobile'
import PrototypeLink from '../prototypeLink/prototypeLink'

export const TraneRentalToolMobile = () => {
  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <img className={styles.cover} src={Cover} alt="Cover" />
        <img
          className={styles.description}
          src={Description}
          alt="Description"
        />
        <img
          className={styles.designProcess}
          src={DesignProcess}
          alt="DesignProcess"
        />
        <img
          className={styles.designProcessImage}
          src={DesignProcessImage}
          alt="DesignProcessImage"
        />
        <img
          className={styles.theResearch}
          src={TheResearch}
          alt="TheResearch"
        />
        <img
          className={styles.researchInsights}
          src={ResearchInsights}
          alt="ResearchInsights"
        />
        <img
          className={styles.userPersonas}
          src={UserPersonas}
          alt="UserPersonas"
        />
        <img
          className={styles.userPersonasImage}
          src={UserPersonasImage}
          alt="UserPersonasImage"
        />
        <img
          className={styles.userJourney}
          src={UserJourney}
          alt="UserJourney"
        />
        <img
          className={styles.clientJourneyBefore}
          src={ClientJourneyBefore}
          alt="ClientJourneyBefore"
        />
        <img
          className={styles.clientJourneyAfter}
          src={ClientJourneyAfter}
          alt="ClientJourneyAfter"
        />
        <img
          className={styles.salesAdministratorBefore}
          src={SalesAdministratorBefore}
          alt="SalesAdministratorBefore"
        />
        <img
          className={styles.salesAdministratorAfter}
          src={SalesAdministratorAfter}
          alt="SalesAdministratorAfter"
        />
        <img className={styles.taskFlow} src={TaskFlow} alt="TaskFlow" />
        <img
          className={styles.taskFlowImage}
          src={TaskFlowImage}
          alt="TaskFlowImage"
        />
        <img
          className={styles.lofiWireframe}
          src={LofiWireframe}
          alt="LofiWireframe"
        />
        <img
          className={styles.lofiWireframeImage}
          src={LofiWireframeImage}
          alt="LofiWireframeImage"
        />
        <img
          className={styles.designSystem}
          src={DesignSystem}
          alt="DesignSystem"
        />
        <img
          className={styles.designSystemImage}
          src={DesignSystemImage}
          alt="DesignSystemImage"
        />
        <img
          className={styles.hifiWireframe}
          src={HifiWireframe}
          alt="HifiWireframe"
        />
        <PrototypeLink
          customStyle={{ paddingBottom: '40px' }}
          link="https://www.figma.com/proto/goTpQKC3ebHKGHqGDkzjd8/Trane---High-fidelity-prototype?node-id=4-14905&node-type=frame&t=i6gvFxxiSvFgraUv-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=4%3A14905"
        />
        <img
          className={styles.usabilityTest}
          src={UsabilityTest}
          alt="UsabilityTest"
        />
        <img
          className={styles.whatDidILearn}
          src={WhatDidILearn}
          alt="WhatDidILearn"
        />
        <div className={styles.thankYouContainer}>
          <p>
            Thank you for taking the time to read this case study. I hope you
            found it interesting and enjoyed exploring the design process. If
            you have any questions, comments, or simply want to connect and
            share ideas, feel free to send me a message on LinkedIn
          </p>
        </div>
        <img src={SeeOtherProjects} alt="SeeOtherProjects" />
        <FooterPage skip="trane" />
      </div>
    </div>
  )
}

export default TraneRentalToolMobile
