import styles from './sushi.module.scss'
import { FooterPage } from '../LandingPage/landingPage'
import Conclusion from './static/Conclusion.svg'
import Cover from './static/Cover.svg'
import Description from './static/Description.svg'
import DesignSystem from './static/DesignSystem.svg'
import DesignSystemImage from './static/DesignSystemImage.svg'
import HifiWireframe from './static/HifiWireframe.svg'
import LofiWireframe from './static/LofiWireframe.svg'
import LofiWireframeImage from './static/LofiWireframeImage.svg'
import SeeOtherProjects from './static/SeeOtherProjects.svg'
import TheObjetiveOfTheProject from './static/TheObjetiveOfTheProject.svg'

export const Sushi = () => {
  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <img className={styles.cover} src={Cover} alt="Cover" />
        <img
          className={styles.description}
          src={Description}
          alt="Description"
        />
        <img
          className={styles.theObjetiveOfTheProject}
          src={TheObjetiveOfTheProject}
          alt="TheObjetiveOfTheProject"
        />
        <img
          className={styles.lofiWireframe}
          src={LofiWireframe}
          alt="LofiWireframe"
        />
        <img
          className={styles.lofiWireframeImage}
          src={LofiWireframeImage}
          alt="LofiWireframeImage"
        />
        <img
          className={styles.designSystem}
          src={DesignSystem}
          alt="DesignSystem"
        />
        <img
          className={styles.designSystemImage}
          src={DesignSystemImage}
          alt="DesignSystemImage"
        />
        <img
          className={styles.hifiWireframe}
          src={HifiWireframe}
          alt="HifiWireframe"
        />
        <iframe
          style={{
            border: '1px solid rgba(0, 0, 0, 0.1)',
            width: '100%',
            height: '95vh',
            marginBottom: '70px',
          }}
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FQZyQG6gjuCNTF01gsgqyn7%2FTrane-portfolio%3Fnode-id%3D704-2287%26node-type%3DSECTION%26t%3D8UM0OuTAswZLBkaR-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26page-id%3D0%253A1"
          allowfullscreen
        ></iframe>
        <img className={styles.conclusion} src={Conclusion} alt="Conclusion" />
        <div className={styles.thankYouContainer}>
          <p>
            Thank you for taking the time to read this case study. I hope you
            found it interesting and enjoyed exploring the design process. If
            you have any questions, comments, or simply want to connect and
            share ideas, feel free to send me a message on LinkedIn
          </p>
        </div>
        <img
          className={styles.seeOtherProjects}
          src={SeeOtherProjects}
          alt="SeeOtherProjects"
        />
        <FooterPage skip="sushi" />
      </div>
    </div>
  )
}

export default Sushi
