import styles from './sushiMobile.module.scss'
import Conclusion from './static/Conclusion.svg'
import Cover from './static/Cover.png'
import Description from './static/Description.svg'
import DesignSystem from './static/DesignSystem.svg'
import DesignSystemImage from './static/DesignSystemImage.svg'
import HifiWireframe from './static/HifiWireframe.svg'
import LofiWireframe from './static/LofiWireframe.svg'
import LofiWireframeImage from './static/LofiWireframeImage.svg'
import SeeOtherProjects from './static/SeeOtherProjects.svg'
import TheObjectiveOfTheProject from './static/TheObjectiveOfTheProject.svg'
import { FooterPage } from '../LandingPageMobile/landingPageMobile'
import PrototypeLink from '../prototypeLink/prototypeLink'

export const SushiMobile = () => {
  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <img className={styles.cover} src={Cover} alt="Cover" />
        <img
          className={styles.description}
          src={Description}
          alt="Description"
        />
        <img
          className={styles.theObjetiveOfTheProject}
          src={TheObjectiveOfTheProject}
          alt="TheObjetiveOfTheProject"
        />
        <img
          className={styles.lofiWireframe}
          src={LofiWireframe}
          alt="LofiWireframe"
        />
        <img
          className={styles.lofiWireframeImage}
          src={LofiWireframeImage}
          alt="LofiWireframeImage"
        />
        <img
          className={styles.designSystem}
          src={DesignSystem}
          alt="DesignSystem"
        />
        <img
          className={styles.designSystemImage}
          src={DesignSystemImage}
          alt="DesignSystemImage"
        />
        <img
          className={styles.hifiWireframe}
          src={HifiWireframe}
          alt="HifiWireframe"
        />
        <PrototypeLink
          customStyle={{ paddingBottom: '40px' }}
          link="https://www.figma.com/proto/QZyQG6gjuCNTF01gsgqyn7/Trane-portfolio?node-id=704-2287&node-type=section&t=rzgvy8LfKcW1urS7-1&scaling=scale-down&content-scaling=fixed&page-id=1266%3A13241"
        />
        <img className={styles.conclusion} src={Conclusion} alt="Conclusion" />
        <div className={styles.thankYouContainer}>
          <p>
            Thank you for taking the time to read this case study. I hope you
            found it interesting and enjoyed exploring the design process. If
            you have any questions, comments, or simply want to connect and
            share ideas, feel free to send me a message on LinkedIn
          </p>
        </div>
        <img
          className={styles.seeOtherProjects}
          src={SeeOtherProjects}
          alt="SeeOtherProjects"
        />
        <FooterPage skip="sushi" />
      </div>
    </div>
  )
}

export default SushiMobile
