import React, { useState, useEffect, useRef } from 'react'
import styles from './imageLoadWrapper.module.scss'
import { useLocation } from 'react-router-dom'

const ImageLoadWrapper = ({ children }) => {
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const loadedCount = useRef(0)
  const totalImages = useRef(0)
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const scrollTo = params.get('scrollTo')
    if (scrollTo === 'index-section') {
      scrollToIndex()
    }
  }, [location])

  const scrollToIndex = () => {
    const elements = document.querySelectorAll('[data-index-item]')
    if (elements.length > 0) {
      elements[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }

  useEffect(() => {
    setImagesLoaded(false)
    loadedCount.current = 0
    totalImages.current = 0

    const imageLoaded = () => {
      loadedCount.current++
      const url = new URL(window.location.href)
      const scrollTo = url.searchParams.get('scrollTo')
      if (
        loadedCount.current === totalImages.current &&
        scrollTo === 'index-section'
      ) {
        scrollToIndex()
        setImagesLoaded(true)
      } else if (loadedCount.current === totalImages.current) {
        setImagesLoaded(true)
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })
      }
    }

    const checkImage = (img) => {
      if (img.complete) {
        imageLoaded()
      } else {
        img.addEventListener('load', imageLoaded)
        img.addEventListener('error', imageLoaded)
      }
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeName === 'IMG') {
            totalImages.current++
            checkImage(node)
          }
        })
      })
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    // Initial check for already loaded images
    const images = document.querySelectorAll('img')
    totalImages.current = images.length
    images.forEach(checkImage)

    // Set imagesLoaded to true if there are no images
    if (totalImages.current === 0) {
      setImagesLoaded(true)
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      })
    }

    return () => {
      observer.disconnect()
      const images = document.querySelectorAll('img')
      images.forEach((img) => {
        img.removeEventListener('load', imageLoaded)
        img.removeEventListener('error', imageLoaded)
      })
    }
  }, [location]) // Re-run effect when location changes

  return (
    <>
      {!imagesLoaded && (
        <div className={styles.loadingOverlay}>
          <span className={styles.loader}></span>
        </div>
      )}
      {children}
    </>
  )
}

export default ImageLoadWrapper
