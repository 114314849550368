import React, { useState, useEffect } from 'react'
import { FooterPage } from '../LandingPage/landingPage'
import ClientAfter from './static/ClientAfter.svg'
import styles from './traneRentalTool.module.scss'
import Cover from './static/Cover.svg'
import Description from './static/Description.svg'
import DesignProcess from './static/DesignProcess.svg'
import DesignProcessImage from './static/DesignProcessImage.svg'
import DesignSystem from './static/DesignSystem.svg'
import DesignSystemImage from './static/DesignSystemImage.svg'
import HifiWireframe from './static/HifiWireframe.svg'
import LofiWireframe from './static/LofiWireframe.svg'
import LofiWireframeImage from './static/LofiWireframeImage.svg'
import SalesAdministratorJouneyAfter from './static/SalesAdministratorJourneyAfter.svg'
import SalesAdministratorJourneyBefore from './static/SalesAdministratorJourneyBefore.svg'
import SeeOtherProjects from './static/SeeOtherProjects.svg'
import TaskFlow from './static/TaskFlow.svg'
import TaskFlowImage from './static/TaskFlowImage.svg'
import TheResearch from './static/TheResearch.svg'
import ResearchInsights from './static/ResearchInsights.svg'
import UsabilityTest from './static/UsabilityTest.svg'
import UserJourney from './static/UserJourney.svg'
import ClientBefore from './static/ClientBefore.svg'
import UserPersonas from './static/UserPersonas.svg'
import UserPersonasImage from './static/UserPersonasImage.svg'
import WhatDidILearn from './static/WhatDidILearn.svg'

export const TraneRentalTool = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false)

  useEffect(() => {
    const imageElements = document.querySelectorAll('img')
    let loadedCount = 0

    const imageLoaded = () => {
      loadedCount++
      if (loadedCount === imageElements.length) {
        setImagesLoaded(true)
      }
    }

    imageElements.forEach((img) => {
      if (img.complete) {
        imageLoaded()
      } else {
        img.addEventListener('load', imageLoaded)
        img.addEventListener('error', imageLoaded) // Count errors as loaded
      }
    })

    return () => {
      imageElements.forEach((img) => {
        img.removeEventListener('load', imageLoaded)
        img.removeEventListener('error', imageLoaded)
      })
    }
  }, [])

  return (
    <div className={styles.page}>
      {!imagesLoaded && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingSpinner}>Loading...</div>
        </div>
      )}
      <div className={styles.pageContent}>
        <img className={styles.coverImage} src={Cover} alt="Cover" />
        <img
          className={styles.description}
          src={Description}
          alt="Description"
        />
        <img
          className={styles.designProcess}
          src={DesignProcess}
          alt="DesignProcess"
        />
        <img
          className={styles.designProcessImage}
          src={DesignProcessImage}
          alt="DesignProcessImage"
        />
        <img
          className={styles.theResearch}
          src={TheResearch}
          alt="TheResearch"
        />
        <img
          className={styles.researchInsights}
          src={ResearchInsights}
          alt="ResearchInsights"
        />
        <img
          className={styles.userPersonas}
          src={UserPersonas}
          alt="UserPersonas"
        />
        <img
          className={styles.userPersonasImage}
          src={UserPersonasImage}
          alt="UserPersonasImage"
        />
        <img
          className={styles.userJourney}
          src={UserJourney}
          alt="UserJourney"
        />
        <img
          className={styles.clientBefore}
          src={ClientBefore}
          alt="ClientBefore"
        />
        <img
          src={ClientAfter}
          alt="ClientAfter"
          className={styles.clientAfter}
        />
        <img
          className={styles.SalesAdministratorJourneyAfter}
          src={SalesAdministratorJouneyAfter}
          alt="SalesAdministratorJourneyAfter"
        />
        <img
          src={SalesAdministratorJourneyBefore}
          alt="SalesAdministratorJouneyBefore"
          className={styles.salesAdministratorJourneyBefore}
        />
        <img className={styles.taskFlow} src={TaskFlow} alt="TaskFlow" />
        <img
          className={styles.taskFlowImage}
          src={TaskFlowImage}
          alt="TaskFlowImage"
        />
        <img
          className={styles.lofiWireframe}
          src={LofiWireframe}
          alt="LofiWireframe"
        />
        <img
          className={styles.lofiWireframeImage}
          src={LofiWireframeImage}
          alt="LofiWireframeImage"
        />
        <img
          className={styles.designSystem}
          src={DesignSystem}
          alt="DesignSystem"
        />
        <img
          className={styles.designSystemImage}
          src={DesignSystemImage}
          alt="DesignSystemImage"
        />
        <img
          className={styles.hifiWireframe}
          src={HifiWireframe}
          alt="HifiWireframe"
        />
        <iframe
          style={{
            border: '1px solid rgba(0, 0, 0, 0.1)',
            width: '100%',
            height: '95vh',
            marginBottom: '70px',
          }}
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FgoTpQKC3ebHKGHqGDkzjd8%2FTrane---High-fidelity-prototype%3Fnode-id%3D4-14905%26node-type%3DFRAME%26t%3DaEtvHMtctHbi2NIn-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26page-id%3D0%253A1%26starting-point-node-id%3D4%253A14905"
          allowfullscreen
        ></iframe>
        <img
          className={styles.usabilityTest}
          src={UsabilityTest}
          alt="UsabilityTest"
        />
        <img
          className={styles.whatDidILearn}
          src={WhatDidILearn}
          alt="WhatDidILearn"
        />
        <div className={styles.thankYouContainer}>
          <p>
            Thank you for taking the time to read this case study. I hope you
            found it interesting and enjoyed exploring the design process. If
            you have any questions, comments, or simply want to connect and
            share ideas, feel free to send me a message on LinkedIn
          </p>
        </div>
        <img
          className={styles.seeOtherProjects}
          src={SeeOtherProjects}
          alt="SeeOtherProjects"
        />
        <FooterPage skip="trane" />
      </div>
    </div>
  )
}
export default TraneRentalTool
