import styles from './iconGallery.module.scss'
import iconsLarge from './static/iconsLarge.svg'
import iconsMedium from './static/iconsMedium.svg'
import iconsMobile1 from './static/iconsMobile1.png'
import iconsMobile2 from './static/iconsMobile2.png'
import iconsMobile3 from './static/iconsMobile3.png'
import iconsMobile4 from './static/iconsMobile4.png'

export const IconGallery = (props) => {
  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        {props.windowWidth < 768 ? (
          <>
            <p className={styles.titleMobile}>
              Icons & <br /> Illustrations
            </p>
            <img className={styles.iconsMobile} src={iconsMobile1}></img>
            <img className={styles.iconsMobile} src={iconsMobile2}></img>
            <img className={styles.iconsMobile} src={iconsMobile3}></img>
            <img className={styles.iconsMobile} src={iconsMobile4}></img>
          </>
        ) : props.windowWidth > 768 && props.windowWidth < 1200 ? (
          <>
            <p className={styles.titleMedium}>
              Icons & <br /> Illustrations
            </p>
            <img src={iconsMedium}></img>
          </>
        ) : (
          <>
            <p className={styles.titleLarge}>
              Icons & <br /> Illustrations
            </p>
            <img src={iconsLarge}></img>
          </>
        )}
      </div>
    </div>
  )
}

export default IconGallery
